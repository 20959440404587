import {
  ButtonStyleOptions,
  AlignmentOptions,
  OfferingListLayoutOptions,
  SettingsKeys,
  ImageAndTextRatioOptions,
  ImagePositionOptions,
  ImageResizeOptions,
  DockLocationOptions,
  ImageShapeOptions,
} from '../../Shared/appKeys/SettingsKeys';

export interface OfferingListWidgetDisplayOptions {
  multiOfferingsDisplayOptions: MultiOfferingsDisplayOptions;
  offeringDisplayOptions: OfferingDisplayOptions;
}

export const MAX_FONT_SIZE_MEDIUM_BUTTON = 18;

export interface MultiOfferingsDisplayOptions {
  layout: OfferingListLayoutOptions;
  title: string;
  isTitleVisible: boolean;
  titleAlignment: AlignmentOptions;
  categoriesDisplayOptions: CategoriesDisplayOptions;
}

export interface CategoriesDisplayOptions {
  isCategoriesVisible: boolean;
  categoriesContentAlignment: AlignmentOptions;
  isCategoriesContentStretch: boolean;
}

export interface OfferingDisplayOptions {
  isOfferingTagLineVisible: boolean;
  isOfferingDividerVisible: boolean;
  isOfferingDurationVisible: boolean;
  isOfferingPriceVisible: boolean;
  isOfferingOfferedDaysVisible: boolean;
  isOfferingStartDateVisible: boolean;
  isOfferingBookButtonVisible: boolean;
  isOfferingImageVisible: boolean;
  isLargeButton: boolean;
  isSecondaryButton: boolean;
  offeringImageResize: ImageResizeOptions;
  offeringImageShape: ImageShapeOptions;
  offeringImageDockLocation: DockLocationOptions;
  offeringTextAlignment: AlignmentOptions;
  layout: OfferingListLayoutOptions;
  buttonStyle: ButtonStyleOptions;
  bookActionText: string;
  noBookActionText: string;
  ratio: ImageAndTextRatioOptions;
  ratioFlipped: boolean;
  imagePosition: ImagePositionOptions;
}

export function getDisplayOptions(
  settingsData,
): OfferingListWidgetDisplayOptions {
  settingsData = settingsData || {};
  return {
    multiOfferingsDisplayOptions: {
      layout: settingsData[SettingsKeys.OFFERING_LIST_LAYOUT],
      title: settingsData[SettingsKeys.MULTI_OFFERINGS_TITLE_TEXT],
      isTitleVisible: settingsData[SettingsKeys.DISPLAY_MULTI_OFFERINGS_TITLE],
      titleAlignment:
        settingsData[SettingsKeys.MULTI_OFFERINGS_TITLE_ALIGNMENT],
      categoriesDisplayOptions: {
        isCategoriesVisible: settingsData[SettingsKeys.DISPLAY_CATEGORIES],
        categoriesContentAlignment:
          settingsData[SettingsKeys.CATEGORY_ALIGNMENT],
        isCategoriesContentStretch:
          settingsData[SettingsKeys.FIT_CATEGORY_WIDTH],
      },
    },
    offeringDisplayOptions: {
      isOfferingTagLineVisible: settingsData[SettingsKeys.DISPLAY_TAG_LINE],
      isOfferingDividerVisible: settingsData[SettingsKeys.DISPLAY_DIVIDER],
      isOfferingDurationVisible: settingsData[SettingsKeys.DISPLAY_DURATION],
      isOfferingPriceVisible: settingsData[SettingsKeys.DISPLAY_PRICE],
      isOfferingOfferedDaysVisible:
        settingsData[SettingsKeys.DISPLAY_DAYS_OFFERED],
      isOfferingStartDateVisible: settingsData[SettingsKeys.DISPLAY_START_DATE],
      isOfferingBookButtonVisible: settingsData[SettingsKeys.DISPLAY_BUTTON],
      isOfferingImageVisible: settingsData[SettingsKeys.DISPLAY_IMAGE],
      offeringImageResize: settingsData[SettingsKeys.IMAGE_RESIZE_OPTION],
      offeringImageShape: settingsData[SettingsKeys.IMAGE_SHAPE_OPTION],
      offeringImageDockLocation: settingsData[SettingsKeys.PIN_LOCATION],
      isLargeButton: false,
      isSecondaryButton:
        settingsData[SettingsKeys.BUTTON_STYLE] ===
          ButtonStyleOptions.SQUARE_HOLE ||
        settingsData[SettingsKeys.BUTTON_STYLE] ===
          ButtonStyleOptions.CIRCLE_HOLE,
      offeringTextAlignment: settingsData[SettingsKeys.TEXT_ALIGNMENT],
      layout: settingsData[SettingsKeys.OFFERING_LIST_LAYOUT],
      buttonStyle: settingsData[SettingsKeys.BUTTON_STYLE],
      bookActionText: settingsData[SettingsKeys.BOOK_FLOW_ACTION_TEXT],
      noBookActionText: settingsData[SettingsKeys.NO_BOOK_FLOW_ACTION_TEXT],
      ratio: settingsData[SettingsKeys.IMAGE_AND_TEXT_RATIO],
      ratioFlipped: settingsData[SettingsKeys.IMAGE_AND_TEXT_RATIO_IS_FLIPED],
      imagePosition: settingsData[SettingsKeys.IMAGE_POSITION_OPTION],
    },
  };
}
