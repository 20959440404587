import s from '../MultiOfferings.st.css';
import { OfferingViewModelFactory } from '../../../domain/offering-view-model-factory';
import OfferingView from '../../OfferingView/OfferingView';
import { translate } from 'react-i18next';
import { withRunningEnvironmentContext } from '../../context/running-environment-context';
import * as React from 'react';

const OfferingListComponent = props => {
  const { t, displayOptions, offeringsDomain, runningEnvironment } = props;
  const { isMobile, isRTL } = runningEnvironment;
  let isVerticalAligned = true;
  const offeringViewModels = offeringsDomain.map(offeringDomain => {
    const offeringViewModel = OfferingViewModelFactory.createOfferingViewModel(
      offeringDomain,
      displayOptions.offeringDisplayOptions,
      t,
      isRTL,
      isMobile,
    );
    isVerticalAligned =
      isVerticalAligned && offeringViewModel.verticalAlignInfoContent;

    return offeringViewModel;
  });

  return (
    <ul className={s.cardsContainer}>
      {offeringViewModels.map(offeringViewModel => {
        offeringViewModel.verticalAlignInfoContent = isVerticalAligned;
        return (
          <li
            className={s.cardContainer}
            key={`offering-${offeringViewModel.id}`}
          >
            <OfferingView offeringViewModel={offeringViewModel} />
          </li>
        );
      })}
    </ul>
  );
};

export const OfferingList = translate(null, { wait: true })(
  withRunningEnvironmentContext(OfferingListComponent),
);
